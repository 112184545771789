/* .cover-background {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    padding: 75px 75px;
    text-align: center;
    justify-content: center;
    z-index: 1;
    background-repeat: no-repeat;
} */

.about-msf iframe {
  width: 47.5%;
}

.cover-background.headerhome {
  width: 100%;
  height: 100%;
  /* background-color: #113377; */
  background-size: cover;
  background-position: center;
  padding: 75px 75px;
  text-align: center;
  justify-content: center;
  z-index: 1;
  background-repeat: no-repeat;
}

.cover-h5 {
  text-align: center;
  font-size: 20px;
  color: white;
}

.cover-h2 {
  text-align: center;
  font-size: 35px;
  color: white;
}

.apply-cover-button {
  margin: auto;
  background-color: #113377;
  color: white;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border: 1px solid white;
  padding: 12px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-section {
  padding: 5em;
  overflow: hidden;
}
.about-msf-section {
  /* width:47.5%;
    line-height: 1.75;
    float: right; */
}

.clear-button {
  color: #0260a8;
  background-color: white;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border: 1px solid #0260a8;
  padding: 12px 14px;
}

.about-sponsor {
  padding-top: 3em;
  text-align: center;
  justify-content: space-between;
}
.about-sponsors {
  justify-content: space-between;
}
.sponsor-logo {
  width: 20%;
}

/* .about-two-button {
    padding-top: 3em;
    text-align: center;
    justify-content: space-between;
} */

/* .about-two-buttons {
    color: white;
    background-color: #0260A8;
    cursor: pointer;
    text-align: center;
    text-decoration: underline;
    border: 1px solid white;
    padding: 12px 14px;
    height: 175px;
    width: 50%;
    font-size: 35px;
} */

/* .about-two-buttons:hover {
    background-color: #071633;
} */

/* .about-highlights {
    width: auto;
    height: auto;
    background-color: #0260A8;
    padding: 75px 75px;
    text-align: center;
} */
.landing-card {
  width: auto;
  height: auto;
  padding: 75px 75px;
  text-align: center;
  justify-items: center;
}
.landing-card-impact {
  padding-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-highlight-boxes {
  display: flex;
  justify-content: space-between;
}

.p-highlight {
  color: white;
  font-size: 24px;
}
.h2-highlight {
  color: white;
  font-size: 40px;
}

/* .carousel.slide {
    width: 33%;
    display: flex;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
} */

.carousel-caption {
  position: unset;
  color: #0260a8;
}
.about-winners {
  margin-top: 2em;
  padding-bottom: 2em;
  padding-right: 17.5em;
  padding-left: 17.5em;
  color: #0260a8 !important;
}
.about-winners h2 {
  color: #0260a8;
  font-size: 40px;
  text-align: center;
}

.clear-button-landing {
  margin: auto;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0260a8;
  background-color: white;
  border: 3px solid #0260a8;
  padding: 12px 14px;
}

/* .sponsor-list {
    align-items: center;
    float: left;
} */

.carousel.slide {
  width: 100% !important;
}

/* .carousel-img-boxy {
    display: flex;
} */
.carousel-img-box {
  margin-left: auto;
  margin-right: auto;
  width: 275px;
  padding-right: 0.5em;
}

.carousel-text-box {
  font-weight: bold;
  font-size: 20px;
  padding-top: 1em;
  display: block;
}

.winner-img-box {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%3fff%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%3fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") !important;
}

/* .sponsor-list .carousel-control-next, .sponsor-list .carousel-control-prev {
    top: 25em !important;
    width: 75% !important;
} */

.sponsor-list .card {
  display: unset;
  min-width: unset;
  border: none;
  color: unset;
}

/*Mobile*/
@media only screen and (max-width: 600px) {
  .about-section {
    padding: 2em;
  }
  .about-msf iframe {
    width: 100% !important;
  }
  .about-msf-section {
    width: 100% !important;
    padding-top: 1em;
  }
  .about-two-button {
    margin-top: 2em;
  }
  .about-two-buttons {
    width: 100%;
    margin-top: 0.5em;
  }

  .about-sponsor {
    padding-top: 2em;
  }
  .sponsor-list {
    float: unset;
  }
  .sponsor-list .carousel-control-next,
  .sponsor-list .carousel-control-prev {
    display: none;
  }
  .carousel-img-boxy {
    display: unset;
  }
  .carousel-img-box {
    width: 350px;
  }

  .about-highlights {
    padding: 2em;
  }
  .about-highlight-boxes {
    display: unset;
    justify-content: unset;
  }
  .about-highlight-box {
    width: 100%;
    margin-top: 1em;
  }

  .about-winners {
    margin: 0;
    padding: 0;
    padding-top: 2em;
  }
  .about-winners .carousel-control-next,
  .about-winners .carousel-control-prev {
    display: none;
  }

  .landing-card {
    padding: 2em;
  }
}

/* ---------------------- */

.cover-background.headerhome {
  width: auto;
  height: 400px;
  /* background-color: #113377; */
  /* background-image: url('../../assets/landing-page.png'); */
  padding: 75px 75px;
  text-align: center;
  justify-content: center;
  z-index: 1;
}

.cover-h5 {
  text-align: center;
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.cover-h2 {
  font-weight: bold;
  text-align: center;
  font-size: 35px;
  color: white;
}

.apply-cover-button {
  margin: auto;
  background-color: #113377;
  color: white;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border: 1px solid white;
  padding: 12px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-section {
  padding: 5em;
  overflow: hidden;
  height: 1400px;
}
.about-msf-section {
  width: 47.5%;
  line-height: 1.5;
  float: right;
}

.clear-button {
  color: #0260a8;
  background-color: white;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border: 1px solid #0260a8;
  padding: 12px 14px;
}

.about-sponsor {
  padding-top: 3em;
  text-align: center;
  justify-content: space-between;
}
.about-sponsors {
  justify-content: space-between;
  /* border: 3px solid red; */
  height: 400px;
  width: 90vw;
  margin-bottom: 300px;
}
.sponsor-logo {
  width: 20%;
}

.about-two-button {
  margin: 4em auto;
  text-align: center;
  justify-content: space-between;
  display: flex;
  width: 90vw;
  margin-top: 6em;
}

.about-two-button a {
  width: 35%;
  transition: transform 0.3s ease;
}

.about-two-button a:hover {
  transform: scale(1.15);
}

.about-two-buttons {
  color: white;
  background-color: #0260a8;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
  border: 1px solid white;
  /* padding: 12px 14px; */
  padding: 0 30px 0 30px;
  height: 175px;
  width: 100%;
  font-size: 32px;
}

.about-two-buttons:hover {
  background-color: #071633;
}

.about-highlights {
  width: auto;
  height: auto;
  background-color: #0260a8;
  /* padding: 75px 75px; */
  padding: 60px 75px 90px 75px;
  text-align: center;
}
.landing-card {
  width: auto;
  height: auto;
  padding: 75px 75px;
  text-align: center;
  justify-items: center;
}
.landing-card-impact {
  padding-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-highlight-boxes {
  display: flex;
  justify-content: space-between;
}
.about-highlight-box {
  width: 30%;
  height: 200px;
  background-color: #458cd1;
  text-align: center;
  justify-items: center;
  padding-top: 1em;
  box-shadow: 0px 0px 8px 8px #b6a065c8;
  border-radius: 3px;
  transition: transform 0.3s ease;
}
.about-highlight-box:hover {
  transform: scale(1.15);
}

/* .p-highlight {
    color: white;
    font-size: 20px;
} */
.h2-highlight {
  color: white;
  font-size: 40px;
}

.carousel.slide {
  width: 100% !important;
  /* width: 33%; */
  display: flex;
  align-content: center;
  justify-content: space-between;
  /* display: block; */
  margin-left: auto;
  margin-right: auto;
  /* border: 3px solid blue; */
}

.sponsor-list .carousel-inner {
  /* border: 3px solid purple; */
  /* overflow: hidden; */
  display: flex;
  justify-content: space-between;
  /* width: fit-content; */
  width: 50%;
  height: 500px;
  margin: 0 auto;
}

.carousel-caption {
  position: unset;
  color: #0260a8;
}

.about-winners {
  margin-top: 2em;
  padding-bottom: 2em;
  padding-right: 17.5em;
  padding-left: 17.5em;
  color: #0260a8 !important;
}
.about-winners h2 {
  color: #0260a8;
  font-size: 40px;
  text-align: center;
}

.clear-button-landing {
  margin: auto;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0260a8;
  background-color: white;
  border: 3px solid #0260a8;
  padding: 12px 14px;
}

.sponsor-list {
  /* align-items: center;
    float: left; */
  /* border: 3px solid red; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.carousel-img-boxy {
  display: flex;
  justify-content: center;
  padding: 0 auto;
  margin: 0 auto;
}
.carousel-img-box {
  margin-left: auto;
  margin-right: auto;
  /* width: 275px; */
  width: 375px;
  padding-right: 0.5em;
  /* border: 3px solid blue; */
  text-align: center;
  max-height: 400px;
  object-fit: contain;

  overflow: hidden;
}

.carousel-text-box {
  font-weight: bold;
  font-size: 20px;
  padding-top: 1em;
  display: block;
  width: 70%;
  word-wrap: normal;
  margin: 0 auto;
  overflow: hidden;
}

.winner-img-box {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%3fff%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%3fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") !important;
}

.carousel-item {
  transition: transform 1.5s ease-in-out;
  /* transition: transform 0.2s ease-in-out; */
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  height: 80px;
  width: 80px;
}

.sponsor-list .carousel-control-next,
.sponsor-list .carousel-control-prev {
  top: -12em !important;
  /* width: 75% !important; */
  width: 15% !important;
  /* bottom: 350em !important; */
  /* border: 10px solid red; */
}

.sponsor-list .carousel-control-next {
  /* left: -30% !important; */
  right: 20%;
  /* border: 3px solid red; */
}
.sponsor-list .carousel-control-prev {
  left: 20%;
  /* left: -5% !important; */
  /* left: -5% !important; */
}

/* --------------------------- winner-img-carousel below --------------- */

.winner-img-carousel .carousel-control-next,
.winner-img-carousel .carousel-control-prev {
  top: 25em !important;
  width: 15% !important;
  bottom: 15em;
  top: 12.5em !important;
  /* bordeR: 3px solid red; */
}

.winner-img-carousel .carousel-control-next {
  /* left: -30% !important; */
  right: -15%;
  /* border: 3px solid red; */
}
.winner-img-carousel .carousel-control-prev {
  left: -15%;
  /* border: 3px solid red; */
  /* left: -5% !important; */
  /* left: -5% !important; */
}

/* ----- End of winner img carousel --------- */

.scholarship-carousel .sponsor-list .card {
  display: unset;
  min-width: unset;
  /* min-width: 30vw; */
  border: none;
  color: unset;
  /* border: 2px solid red; */
}

/*Mobile*/
@media only screen and (max-width: 600px) {
  .about-section {
    padding: 2em;
    height: 2100px !important;
    /* border: 2px solid red; */
  }
  .about-msf iframe {
    width: 100% !important;
  }
  .about-msf-section {
    width: 100% !important;
    padding-top: 1em;
  }
  .about-two-button {
    /* margin-top: 2em; */
  }
  .about-two-buttons {
    width: 100%;
    /* margin-top: 0.5em; */
  }

  .about-sponsor {
    padding-top: 2em;
  }
  .sponsor-list {
    float: unset;
  }
  .sponsor-list .carousel-control-next,
  .sponsor-list .carousel-control-prev {
    display: none;
  }
  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }
  .carousel-img-boxy {
    display: unset;
  }
  .carousel-img-box {
    width: 350px;
  }

  .about-highlights {
    padding: 2em;
  }
  .about-highlight-boxes {
    display: unset;
    justify-content: unset;
  }
  .about-highlight-box {
    width: 100%;
    margin-top: 1em;
  }

  .about-winners {
    margin: 0;
    padding: 0;
    padding-top: 2em;
  }
  /* .about-winners .carousel-control-next, .about-winners .carousel-control-prev {
        display: none;
    } */

  .landing-card {
    padding: 2em;
  }
}

/* Responsive Design Below */

/* Existing styles... */

/* Responsive Design for max-width of 768px */
@media screen and (max-width: 768px) {
  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }

  .about-msf {
    margin-bottom: 2rem;
  }

  .about-section {
    height: 1900px;
  }

  .about-section,
  .about-highlights,
  .landing-card {
    padding: 2em; /* Reduced padding for smaller screens */
  }

  .about-msf iframe,
  .about-msf-section,
  .carousel-img-box {
    width: 100%; /* Full width for better usability */
  }

  .about-highlight-boxes,
  .sponsor-list {
    display: block; /* Stack elements vertically */
  }

  .about-highlights h2 {
    margin: 0;
    padding: 0;
  }

  .about-sponsor h2 {
    font-size: 36px;
  }

  .about-highlight-box,
  .sponsor-list .card {
    width: 100%; /* Full width for each highlight box and sponsor card */
    margin-bottom: 2em; /* Space between stacked elements */
  }

  .about-winners {
    padding: 2em 0; /* Adjust padding */
    margin-bottom: 2em;
  }

  .about-winners h2 {
    font-size: 36px;
    padding-top: 1em;
  }

  .landing-card h2 {
    font-size: 36px;
  }

  .winner-img-box {
    width: 90%; /* Adjust carousel image width */
  }

  .about-two-button {
    flex-direction: column;
    width: 100%;
    margin-top: 2em !important;
  }

  .about-two-button > * {
    text-align: center;
    width: 100% !important;
  }

  .about-two-buttons {
    margin: 0;
    text-align: center;
    font-size: 2em;
    width: 100%; /* Full width for buttons */
    /* margin-top: 0.5em; */
    /* Space between stacked buttons */
  }

  .about-sponsors,
  .about-winners {
    padding: 0; /* Remove padding */
    margin: 0 auto 5em auto;
  }
  .carousel-text-box {
    width: 100%;
  }
}

/* Responsive Design for min-width of 769px and max-width of 1024px */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .sponsor-list .carousel-control-next {
    /* left: -30% !important; */
    right: 10%;
    /* border: 3px solid red; */
  }
  .sponsor-list .carousel-control-prev {
    left: 10%;
    /* left: -5% !important; */
    /* left: -5% !important; */
  }

  .about-two-button {
    /* border: 5px solid black; */
    display: flex;
    width: unset;
    margin: 2em auto !important;
  }

  .about-two-button * {
    box-sizing: border-box;
  }

  .about-two-button a {
    box-sizing: border-box;
    /* width: 100% !important; */
    flex: 33.3%;
    /* margin: 0 auto; */
    /* border: 2px solid red; */
  }

  .about-two-buttons {
    box-sizing: border-box;
    /* border: 4px solid blue; */
    font-size: 1.8em;
    width: 100% !important;
  }

  .about-msf {
    display: flex;
    flex-direction: column;
    margin: 0 2em 0 2em;
  }

  .about-msf iframe {
    width: 100% !important;
    margin: 0 auto 2em auto;
  }

  .about-section {
    height: 1600px;
  }

  .about-section,
  .about-highlights,
  .landing-card {
    padding: 3em; /* Moderate padding for medium screens */
  }

  .about-msf-section,
  .carousel-img-box {
    width: 90%; /* Slightly reduced width for medium screens */
  }

  .about-highlight-boxes,
  .sponsor-list {
    display: flex; /* Keep elements in a row with wrapping */
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: center;
  }

  .about-highlight-boxes > * {
    margin: 1em;
  }

  .about-highlight-box,
  .sponsor-list .card {
    width: 45%; /* Adjust width for each highlight box and sponsor card */
    margin-bottom: 1em; /* Space between wrapped elements */
  }

  .sponsor-list .card {
    /* border: 3px solid red; */
    width: 80%;
    margin: 0 auto;
  }

  .about-winners {
    padding: 3em 1em; /* Adjust padding */
  }

  .winner-img-box {
    width: 80%; /* Adjust carousel image width */
  }

  .about-two-buttons {
    width: 45%; /* Adjust width for buttons */
    margin: 0.5em; /* Space between buttons */
  }

  .about-sponsors,
  .about-winners {
    padding: 1em; /* Adjust padding */
  }
}

/* End of CSS */
