.apply-container {
    color: #0260A8;
    padding-left: 7em;
    padding-right: 7em;
    padding-top: 3em;
    padding-bottom: 3em;
}
.date-container {
    padding-top: 2em;
}

.title-container {
    padding-top: 1em;
    width: 20%;
    display:inline-block;
    font-weight: bold;
}

.dates-container{
    width: 50%;
    display:inline-block;
}