.btn-primary {
    color: #fff;
    font-family: 'DM Sans', sans-serif;
    background-color: #0260A8;
    border-color: #0260A8;
    width: 70px;
    height: 35px;
}

.navbar {
    min-height: 100px !important; 
}

.custom-nav-links a {
    color: #0260A8 !important;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    margin-right: 15px;
}

@media (max-width: 768px) {
  .custom-nav-links a {
    margin-right: 50px;
  }
}


@media (max-width: 992px) {
 .btn-primary.nav-apply-btn {
  /* display: none; */
  order:1;
 }

 #basic-navbar-nav {
  /* order: -1; */
 }

 button.navbar-toggler.collapsed {
  order: 2;
 }


 .container .navbar-brand {
   /* border: 3px solid red; */
   /* order: 0; */
  }

  /* whole navbar */
  .navbar-custom {
   /* border: 3px solid blue; */
  }
}
