.about-privacy {
    padding: 5em;
}

.about-privacy h2 {
    font-weight: bold;
}

.about-privacy h4 {
    font-style: italic;
    font-size: 18px;
    color: #bdbdbd;
}

.about-privacy h3 {
    font-size: 20px;
    font-weight: bold;
}

.about-privacy p {
    font-size: 16px;
}

.privacy-italic {
    font-style: italic;
}

/*Mobile*/
@media only screen and (max-width: 600px) {
    .about-privacy {
        padding: 2em;
    }
}