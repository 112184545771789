
.header-container {
  /* min-height: 400px; */
  /* height: auto; */
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
  image-rendering:crisp-edges;
}

.header-photo {
  width: 100%;
  image-rendering:crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: optimizeQuality;
  image-resolution: initial;
  /* border: 2px solid red; */
  /* height: auto; */
  object-fit: cover;
  /* width: auto; */
  /* height: 100%; */
  max-height: 400px;
  
}



/* .cover-h2, .cover-h5 {
  padding-top: 0.5em;
}
.cover-h2 {
  font-size: 40px;
}
.cover-h5 {
  font-size: 24px;
  color: #0260A8;
}
.apply-cover-button {
  margin-top: 2em;
  background-color: #0260A8 !important;
  border: none;
}

.cover-background.headerhome {
  background-image: url('https://res.cloudinary.com/dvajyzict/image/upload/v1706912509/Homepage.jpeg.jpg');
} */