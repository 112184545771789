.cover.main {
    position: relative;
    /* min-height: 550px; */
    background-repeat: no-repeat;
  }

  .cover.main-header {
    height: 100%;
    /* background-color: #113377; */
    background-size: cover;
    background-position: center;
    text-align: center;
    justify-content: center;
    z-index: 1;
    background-repeat: no-repeat;
  }
  
  .cover-background.main {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
  }


  /* .cover-background.main {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    background-size: cover;
    background-position: center;
    padding: 75px 75px;
    z-index: 1;
    background-repeat: no-repeat;
  } */
  

  
  .header-container.main {
    z-index: 1;
    text-align: center;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .cover-h5.main {
    font-size: 65px;
    margin: 15px; /* Reset margin to remove default margin */
    font-weight: bold;
  }
  
  .cover-h2.main {
    font-weight: bold;
    font-size: 35px;
    margin: 10px 0; /* Add margin to the top and bottom */
  }
  



  