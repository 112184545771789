
.scholarship-detail-main-container {
    /* border: 2px solid red; */
    height: fit-content;
    margin: 10vh;
    margin-top: 5vh;
}
.scholarship-detail-main-container > * {
    margin: 30px;
}

.scholarship-detail-header {
    /* border: 2px solid red; */
    
}

.scholarship-detail-header-name {
    font-weight: bold;
    margin: 0;
}

.scholarship-detail-header-amount {
    color: #b6a065c8;
    font-weight: bold;
    margin: 0;
}

.scholarship-detail-body {
    box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
    border-radius: 15px;
    border: 1px solid grey;
    padding: 20px;
    display: flex;
}


.scholarship-detail-body-left-pane {
    width: 30%;
    /* border: 2px solid red; */
    text-align: center;
}


.scholarship-detail-body-right-pane {
    /* border: 2px solid red; */
    width: 70%;
}

.back-btn-container { 
    /* border: 2px solid red; */
}

.scholarship-detail-back-btn {
    background-color: #0260A8;
    padding: 12px 14px;
    color: white;
    border-radius: 5px;
}

/* Responsive Design Below */

@media (max-width: 768px) {
    .scholarship-detail-main-container {
        margin: 5vh 2vh; /* Smaller margin for smaller screens */
    }
    .scholarship-detail-main-container > * {
        margin: 15px; /* Smaller margin for container children */
    }
    .scholarship-detail-header-name,
    .scholarship-detail-header-amount {
        font-size: 1.2rem; /* Adjust font size for header */
    }
    .scholarship-detail-body {
        flex-direction: column; /* Stack elements vertically */
        padding: 10px;
    }
    .scholarship-detail-body-left-pane,
    .scholarship-detail-body-right-pane {
        width: 100%; /* Full width for each pane */
        margin: 0 0 30px 0;
    }
    .scholarship-detail-back-btn {
        padding: 8px 10px; /* Smaller padding for the back button */
    }

    .back-btn-container {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .scholarship-detail-main-container {
        margin: 7vh 3vh; /* Moderate margin for medium screens */
    }
    .scholarship-detail-main-container > * {
        margin: 20px; /* Moderate margin for container children */
    }
    .scholarship-detail-header-name,
    .scholarship-detail-header-amount {
        font-size: 1.4rem; /* Adjust font size for header */
    }
    .scholarship-detail-body {
        flex-direction: row; /* Keep elements in a row */
        padding: 15px;
    }

    .scholarship-detail-body > * {
        padding: 10px;
    }

    .scholarship-detail-body-left-pane {
        width: 40%; /* Adjust width for left pane */
    }
    .scholarship-detail-body-right-pane {
        width: 60%; /* Adjust width for right pane */
    }
    .scholarship-detail-back-btn {
        padding: 10px 12px; /* Moderate padding for the back button */
    }
}

/* End of CSS */
