.centered-content {
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  .centered-text { 
    max-width: 1200px;
    padding: 20px 20px;
    font-size: 18px;
    color: #0260A8;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    line-height: 130%;
  }
  
  
  .centered-tabs-container {
    display: flex;
    flex-direction: column; /* Set to column layout */
    align-items: center;
    max-width: 50%;
    margin: 0px auto 100px auto;
  }
  
  .centered-tabs-container .nav {
    display: flex;
    justify-content: space-around;
    justify-content: center;
    border-bottom: 0px; 
  }
  
  .centered-tabs-container .nav-item {
    flex: 1;
    text-align: center;
    
  }
  
  .centered-tabs-container .nav-link {
    font-size: 25px;
    font-family: 'DM Sans', sans-serif;
    color: #0260A8;
    font-weight: bold;
    padding: 10px 20px;
    
  }
  
  .centered-tabs-container .nav-tabs .nav-link.active {
    background-color: #0260A8;
    color: #fff;
    

  }

  .centered-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    /* background-color: #D9D9D9; */
  }
  
  .pdf-card {
    margin-right: 100px; /* Adjust the value for space between cards */
    background-color: #0260A8;
  }
  
  .pdf-card:last-child {
    margin-right: 0; /* No margin for the last card in each row */
  }
  
  /* Media Query for smaller screens */
  @media (max-width: 768px) {
    .centered-cards .pdf-card {
      margin-right: 0; /* Remove right margin for cards on smaller screens */
      margin-bottom: 20px; /* Add bottom margin for space between stacked cards */
    }
  }


.custom-card-title {
    text-align: left;
    color: white; 
    font-family: 'DM Sans', sans-serif;
  }
  
.custom-link {
    text-align: left;
    font-family: 'DM Sans', sans-serif;

}


.report-coming-soon {
  /* border: 3px solid red; */
  border: 1px solid black;
  border-radius: 5px;
  width: 18rem;
  background-color: white;
  height: 300px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0260A8;
  font-size: 2em;
  height: 13em;
}
  