.container {
    max-width: 100%;
    padding-left: 4em;
    padding-right: 4em;
    color: white;
}
.text-body-secondary {
    color: white !important;
}
.btn.btn-primary.footer {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    width: auto;
    font-weight: 600;
    border-radius: 0;
}

.footer-icons {
    margin-top: 30px;
    padding-top: 5px;
    width: 20%;
    display: flex;
    justify-content: space-between;

}

.website-terms-and-conditions {
    text-decoration: none;
    color: white;
}
.website-terms-and-conditions:hover {
    text-decoration: underline;
}

.footer-links .nav-link.p-0.text-body-secondary {
    width: fit-content;
}
.footer-links .nav-link.p-0.text-body-secondary:hover {
    text-decoration: underline;
}

/*Mobile*/
@media only screen and (max-width: 1024px) {
    .footer-icons * {
     margin-right: 1em;
    }
}


@media only screen and (max-width: 600px) {
    .container {
        padding-right: 2em;
        padding-left: 2em;
    }
    .container .py-5 {
        padding-top: 2em !important;
        padding-bottom: 2em !important;
    }
    .container .row {
        display: unset;
    }
    .container .row .col-6 {
        width: 100% !important;
    }
    .container .row .col-6 .col-md-2 .mb-3 {
        width: 100% !important;
        margin-right: 0 !important;
    }
    .container .col-md-5 {
        margin-left: 0 !important;
    }
}