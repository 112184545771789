.my-component {
  font-family: "Arial", sans-serif;
  color: #0260a8;
  /* border: 2px solid red; */
}

.apply-btns {
  display: flex;
  justify-content: space-between;
  margin: 0 35% 0 35%;
}

.app-row-sch {
  display: flex;
  justify-content: center;
}

.scholar-app-btn-wrapper {
  /* border: 2px solid red; */
}

/* .app-btn { */
.scholar-app-btn {
  /* width: fit-content !important; */
  width: 220px !important;
}

.main-div {
  /* border: 4px solid blue !important; */
  width: 95vw;
  margin: 0 auto;
}

.scholarship-info p {
  font-size: 1.2rem;
  margin: 15px auto;
  text-align: left;
  width: 80%;
}

.image-placeholder {
  background-color: red;
  width: 150px;
  height: 150px;
  margin-right: 15px;
}

button {
  font-weight: bold;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-title {
  font-style: italic;
  color: #000;
}

.card-text {
  font-size: 16px;
}

.scholar {
  /* border: 1px solid red; */
  height: 180px;
  min-height: fit-content;
  width: 20%;
  margin: 2vw;
  display: flex;
  flex-direction: column;
}

.scholarships-list-container {
  /* border: 2px solid blue; */
  /* margin-bottom: 10%; */
  width: 80vw;
  /* margin: 0 auto 0 10%; */
  margin: 0 auto;
  margin-bottom: 10%;
  /* border: 8px double #0260A8;
    border-radius: 15px; */
}

.scholarships-list {
  background-color: #d9d9d9;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 10px 0 10px;

  /* border: 2px solid red; */
}

.scholar-photo {
  height: auto;
  width: 20%;
  margin: 0 auto;
}

.scholar-title {
  font-weight: bold;
  font-size: 1.4rem;
  height: 20%;
  margin-bottom: 0;
}

.scholar-name {
  font-weight: bold;
  width: 80%;
  align-self: center;
}

.scholar-details p {
  margin: 0;
}

.scholar-amount {
  font-weight: bold;
  color: #b6a165;
}

.scholar-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: white;
  border: 1px solid blue;
  height: 100%;
}

.tabs-container {
  /* border: 2px solid red; */
  display: flex;
  text-decoration: none;
  flex-direction: row;
  border-top: 3px solid #d9d9d9;
  border-right: 3px solid #d9d9d9;
  border-left: 3px solid #d9d9d9;
  border-radius: 5px;
}

.selected {
  background-color: #d9d9d9; /* darker */
  /* -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #0260A8; */
}

.tab {
  /* border: 2px solid red; */
  width: 50%;
  text-align: center;
  font-size: 3rem;
  /* background-color: #f2f1f1; */
}

.post-secondary-tab,
.highschool-tab {
  /* color: white;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #0260A8;   */
  /* color: #0260A8; */
  /* font-weight: bold; */
  color: grey;
}

.post-secondary-tab {
  /* background-color: #f2f1f1; */
  font-size: 26px;
}

.highschool-tab {
  font-size: 26px;
}

.key {
  color: #0260a8;
  font-weight: bold;
  background-color: #f2f1f1;
  box-shadow: 0px 0px 5px 5px #b6a065c8;
  /* color: #0260A8; */
  /* color: #0260A8; */
}

.scholar-view-more {
  font-weight: bold;
  font-size: 0.8rem;
}

.scholarship-name-wrapper {
  display: flex;
  justify-content: space-between;
}

.learn-more-btn {
  width: 90px !important;
}

.btn-primary.scholarships-open-btn {
  width: fit-content;
}

/* Responsive Design Below */

/* Existing styles... */

@media (max-width: 768px) {
  .main-div,
  .scholarships-list-container {
    width: 100%; /* Full width for better space utilization */
  }

  .scholarship-info p,
  .tabs-container .tab {
    font-size: 1rem; /* Smaller font size for text and tabs */
    text-align: center; /* Center align text for better readability */
  }

  .tab {
    width: unset;
    flex: 40%;
    padding: 10px;
  }

  .apply-btns {
    display: block; /* Stack buttons vertically */
    margin: 0 auto; /* Center align buttons */
    width: 100%; /* Full width for buttons container */
  }

  .scholar-app-btn {
    width: auto; /* Adjust width to fit content */
    margin: 10px 0; /* Add margin between buttons */
  }

  .tabs-container {
    /* Stack tabs vertically */
    /* flex-direction: column;  */
  }

  .post-secondary-tab,
  .highschool-tab {
    font-size: 20px; /* Smaller font size for tabs */
  }

  .scholarship-name-wrapper {
    flex-direction: column; /* Stack scholarship name and button vertically */
    align-items: center; /* Center align items */
    margin-bottom: 15px;
    padding: 10px 10px 20px 10px;
    border-bottom: 1px solid grey;
  }

  .learn-more-btn {
    width: auto; /* Adjust width to fit content */
    margin-top: 10px; /* Add margin above the button */
  }

  .winner-modal-container,
  .modal-dialog {
    flex-direction: column; /* Stack modal elements vertically */
    max-width: 90vw; /* Adjust modal dialog width */
    margin: 2em auto;
  }

  .winner-modal-img {
    width: 100%; /* Full width for modal images */
    height: auto; /* Adjust height accordingly */
  }

  .winner-modal-bio {
    font-size: 1rem; /* Smaller font size for modal biography */
  }

  .scholar {
    width: 100%; /* Full width for scholarship cards */
    margin: 10px 0; /* Add margin between cards */
  }

  .accordion-body {
    font-size: 1rem; /* Adjust font size in accordion body */
  }

  /* Additional responsive adjustments as needed... */
}

/* End of CSS */
