* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: sans-serif;
    color: #0260A8;
}


header h1 {
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
}

.contact-container.faq {
    padding: 5em;
}

.faqs {
    width: 100%;
    margin: 0 auto;
    padding: 15px;
}

.faqs .faq {
    margin: 15px;
    padding: 15px;
    background: #F7F7F7;
    border: 1px solid #0260A8;
    box-shadow: 4px 2px 2px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
    position: relative;
    font-size: 20px;
    padding-right: 80px;
    transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
    margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
    content: "-";
}

.faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
}

.contact-button {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    width: auto;
    font-weight: 600;
    border-radius: 0;
    margin: 0 auto;
    display: block;
    height: 3em !important;
}

/*Mobile*/
@media only screen and (max-width: 600px) {
    .contact-container {
        padding: 2em;
    }
    .contact-container .faqs {
        padding: 0;
    }
}


/* Responsive Design Below */
/* Existing styles... */

/* Responsive Design for max-width of 768px */
@media only screen and (max-width: 768px) {
    .contact-container.faq {
        padding: 2em; /* Reduced padding for smaller screens */
    }

    .faqs {
        padding: 10px; /* Adjust padding for FAQ container */
    }

    .faqs .faq {
        margin: 10px 0; /* Adjust margin for each FAQ */
        padding: 10px; /* Adjust padding inside FAQ */
    }

    .faqs .faq .faq-question {
        font-size: 18px; /* Adjust font size for questions */
        padding-right: 40px; /* Adjust padding for questions */
    }

    .faqs .faq .faq-question::after {
        width: 20px; /* Adjust size for toggle icon */
        height: 20px; /* Adjust size for toggle icon */
    }

    .contact-button {
        font-size: 14px; /* Adjust font size for button */
        padding: 10px 20px; /* Adjust padding for button */
    }
    .faq-title {
        font-size: 2.2em;
    }
}

/* Responsive Design for min-width of 769px and max-width of 1024px */
@media only screen and (min-width: 769px) and (max-width: 1024px) {

    .contact-container.faq {
        padding: 3em; /* Moderate padding for medium screens */
    }

    .faqs {
        padding: 15px; /* Adjust padding for FAQ container */
    }

    .faqs .faq {
        margin: 15px 5px; /* Adjust margin for each FAQ */
        padding: 15px; /* Adjust padding inside FAQ */
    }

    .faqs .faq .faq-question {
        font-size: 20px; /* Adjust font size for questions */
        padding-right: 60px; /* Adjust padding for questions */
    }

    .faqs .faq .faq-question::after {
        width: 25px; /* Adjust size for toggle icon */
        height: 25px; /* Adjust size for toggle icon */
    }

    .contact-button {
        font-size: 16px; /* Adjust font size for button */
        padding: 12px 24px; /* Adjust padding for button */
    }
}

/* End of CSS */
