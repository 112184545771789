.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-text {
  max-width: 1200px;
  padding: 20px 20px;
  font-size: 18px;
  color: #0260a8;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  line-height: 130%;
}

.centered-tabs-container .nav-link {
  font-size: 25px;
  font-family: "DM Sans", sans-serif;
  color: #0260a8;
  font-weight: bold;
  padding: 10px 20px;
}

.centered-tabs.pw {
  /* border : 2px solid red; */
  /* margin: 30px auto 30px auto; */
  margin: 0;
  text-align: center;
  border-radius: 15px;
  background-color: #ebebeb;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  flex-wrap: wrap;
  align-items: center;
  /* border: 3px solid blue; */
}

.winner-card.pw:hover {
  /* box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2); */
  /* box-shadow: 0px -15px 12px 12px rgba(0, 0, 255, .2); */
  /* box-shadow: 0px -10px 30px -5px rgba(0, 0, 255, .2); */
  /* font-size: 30px; */
}

.winner-text-wrapper:hover {
  /* box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2); */
}

.centered-tabs.pw .nav {
  display: flex;
  justify-content: space-around;
  justify-content: center;
  border-bottom: 0px;
  margin: 0 !important;
  padding-bottom: 0;
}

.centered-tabs .nav-item {
  flex: 1;
  text-align: center;
}

.centered-tabs .nav-link {
  font-size: 25px;
  font-family: "DM Sans", sans-serif;
  color: #0260a8;
  font-weight: bold;
  padding: 10px 20px;
}

.centered-tabs.pw .nav-tabs .nav-link.active {
  background-color: #0260a8;
  color: #fff;
}

.pdf-card {
  margin-right: 100px; /* Adjust the value for space between cards */
  background-color: #0260a8;
}

.pdf-card:last-child {
  margin-right: 0; /* No margin for the last card in each row */
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .centered-cards .pdf-card {
    margin-right: 0; /* Remove right margin for cards on smaller screens */
    margin-bottom: 20px; /* Add bottom margin for space between stacked cards */
  }
}

.custom-card-title {
  text-align: left;
  color: white;
  font-family: "DM Sans", sans-serif;
}

.custom-link {
  text-align: left;
  font-family: "DM Sans", sans-serif;
}

.winner-modal-img {
  height: 15vw;
  /* width: 250px; */
  width: auto;
}

#scholar-modal-img {
  max-width: 350px;
}

.modal-dialog {
  max-width: 100vw;
}

.modal-content {
  /* border: 3px solid red; */
  width: 70%;
  margin: 0 auto;
}

.winner-modal-container {
  display: flex;
  /* border: 3px solid red; */
  margin: 0 auto;
  width: 90%;
}

.winner-modal-container * {
  margin: 20px;
}

.winner-modal-name {
  font-weight: bold;
  margin: 0;
}

.winner-modal-scholarship {
  color: #b6a065c8;
  font-weight: bold;
  margin: 0;
}

.winner-modal-bio {
  font-size: 1.2em;
}

.modal-header button.btn-close {
  display: none;
}

/* ------------------------- */

.centered-tabs-container.pw .nav-tabs .nav-link.active {
  background-color: #0260a8;
  color: #fff;
}

.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.winner-img {
  height: 100%;
  width: auto;
  /* width: 100%;
  height: auto; */
  /* margin-bottom: 30px !important; */
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
}

.winner-img:hover {
  cursor: pointer;
  /* height: 130%; */
  /* width: auto; */
}

.centered-tabs-container.pw {
  max-width: 80% !important;
}

.centered-tabs-container.pw .nav {
  width: 50vw;
  min-width: 750px;
  margin-bottom: 0;
  margin-top: 15px;
}

.centered-tabs-container.pw .nav-link {
  font-size: 1.5vw;
  margin: 0;
  border-top: 1px solid grey;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
}

.winner-img-wrapper {
  height: 72%;
  z-index: -5;
  width: 100%;
  overflow: hidden;
  text-align: center;
  border-radius: 10px;
}

.winner-text-wrapper {
  /* border: 3px solid #B6A165; */
  /* background-color: #0260A8; */
  border-radius: 10px;
  /* margin-top: 50px; */
  height: 50%;
  /* margin-bottom: 30px !important; */
  /* height: fit-content; */
  display: flex;
  /* justify-content: flex-start;
  align-items: flex-start; */
  text-align: center;
  /* justify-content: center; */
  padding: 5px 12px 5px 12px;
  flex-direction: column;
  z-index: 5;
  opacity: 1;
}

.winner-card.pw {
  /* background-image: url("../../assets/winners/winner-1.png"); */
  /* background-color: #ebebeb; */
  /* background-image: url("../../assets/MAX-Logo-Transparent-1.png"); */
  position: relative;
  background-size: 20%;
  background-repeat: repeat;
  /* background-size: cover; */
  /* background-color: white; */
  border-radius: 10px;
  height: 350px;
  margin: 50px 10px 15px 10px;
  /* border: 2px solid red; */
  /* height: auto; */
  width: 20%;
  min-width: 220px;
}

.winner-name {
  color: #0260a8;
  font-weight: bold;
  font-size: 20px;
  margin: 0;
}

.winner-scholarship {
  color: #b6a165;
  font-weight: bold;
  font-size: 18;
}

.centered-tabs.pw .nav {
  display: flex;
  justify-content: space-around;
  justify-content: center;
  border-bottom: 0px;
  margin: 0 !important;
  padding-bottom: 0;
}

.centered-tabs.pw .nav-item {
  flex: 1;
  text-align: center;
}

.pw-tab {
  /* border: 2px solid red; */
}

.centered-tabs.pw .nav-link {
  font-size: 25px;
  font-family: "DM Sans", sans-serif;
  color: #0260a8;
  font-weight: bold;
  padding: 10px 20px;
}

.centered-tabs.pw .nav-tabs .nav-link.active {
  background-color: #0260a8;
  color: #fff;
}

/* .centered-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
} */

.pdf-card {
  margin-right: 100px; /* Adjust the value for space between cards */
  background-color: #0260a8;
}

.pdf-card:last-child {
  margin-right: 0; /* No margin for the last card in each row */
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .centered-cards .pdf-card {
    margin-right: 0; /* Remove right margin for cards on smaller screens */
    margin-bottom: 20px; /* Add bottom margin for space between stacked cards */
  }
}

@media (max-width: 1100px) {
  .winner-modal-container {
    flex-direction: column;
  }

  .winner-modal-bio {
    font-size: 2vw;
  }

  .winner-modal-img {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.custom-card-title {
  text-align: left;
  color: white;
  font-family: "DM Sans", sans-serif;
}

.custom-link {
  text-align: left;
  font-family: "DM Sans", sans-serif;
}

/* Style for the image */
.winner-img {
  /* width: 100%; */
  transition: filter 0.5s ease; /* Smooth transition for the filter */
}

/* New CSS */
/* Initial style for the FontAwesome icon - hidden by default */

.info-icon {
  position: absolute;
  /* bottom: 50px; */
  top: 40%;
  left: 50%;
  /* border: 1px solid black; */
  border-radius: 25px;
  /* bottom: 150px; */
  transform: translate(-50%, -50%);
  color: #b6a165; /* Icon color */
  font-size: 60px; /* Icon size */
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 10;
  cursor: pointer;
}

/* Style for the hover effect on the image and icon */
.winner-card:hover .winner-img {
  filter: brightness(40%); /* Darkens the image */
}

.winner-card:hover .info-icon {
  opacity: 1; /* Makes the icon visible on hover */
}

/* END CSS */

/* Responsive CSS Below */

@media screen and (max-width: 768px) {
  html,
  body {
    max-width: 100%;
    overflow-x: hidden;
  }
  .centered-content,
  .centered-tabs-container.pw .nav,
  .winner-modal-container {
    /* flex-direction: column; */
  }

  .centered-text,
  .centered-tabs-container.pw,
  .modal-content,
  .winner-card.pw {
    width: 100%; /* Full width for better utilization of space */
  }

  .centered-tabs.pw {
    padding: 10px 20px 50px 20px;
  }

  .centered-tabs-container .nav-link,
  .centered-tabs .nav-link {
    font-size: 18px; /* Smaller font size for tab links */
    padding: 8px 10px;
  }

  .winner-card.pw {
    margin: 20px 5px; /* Reduced margin for cards */
    width: auto; /* Adjust width to fit content */
    min-width: 180px; /* Smaller minimum width */
    height: auto; /* Height adjusted to content */
  }

  .winner-text-wrapper {
    font-size: 16px; /* Smaller font size for text */
    padding: 5px; /* Reduced padding */
  }

  .winner-name,
  .winner-scholarship {
    font-size: 16px; /* Adjusted font size for winner's details */
  }

  .winner-img {
    width: 100%;
  }

  .winner-modal-img {
    /* Adjust modal image width */
    width: 90%;
    /* Adjust modal image height */
    height: auto;
  }

  .winner-modal-bio {
    font-size: 16px; /* Smaller font size for modal biography */
  }

  .info-icon {
    font-size: 30px; /* Smaller icon size */
    top: 30%; /* Adjust icon position */
  }

  .modal-dialog {
    max-width: 95vw; /* Larger modal dialog for better visibility */
  }

  .pdf-card {
    margin-right: 0; /* Remove right margin for PDF cards */
  }

  .centered-tabs-container.pw .nav-tabs .nav-link.active,
  .centered-tabs-container.pw .nav-link {
    font-size: 7vw; /* Adjust font size */
    margin: 0;
    border-top: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    width: 100%;
  }

  .centered-tabs-container.pw .nav {
    min-width: unset;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .centered-tabs-container.pw .nav li.nav-item {
    width: 40%;
  }

  /* Additional styles for other elements as needed... */
}

/* End of CSS */
